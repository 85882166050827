body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading{
  font-size: 54px;
  margin-left:  10px;
  margin-right:  10px;
  font-weight: bold; 
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: white;
}
.subHeading{
  font-size: 30px;
  margin-left:  10px;
  margin-right:  10px;
  font-weight:normal; 
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: white;
  opacity: 0.9;
}

.rows .row {
  display: inline-block;
}

.header{
  position: fixed;
  top: 0;   
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  backdrop-filter: blur(4px); 
  background-color: #040e27ce; 
	z-index: 1;  
}

.navHeaderLogo{
  font-size: 24px;
  font-weight: bold; 
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: white;
  display: flex; 
}
.navHeaderLogoImgBox{
  display: flexbox;    
  height: 1; 
}
.navHeaderLogoImg{
  display: flex; 
  height: 100%;
  align-items: center;  
}
#navHeaderLogoStyle{
  height: 36px;
  width: 36px; 
}
.navHeaders{
  display: flex; 
  height: 100%;
  margin-right: 50px; 
  right: 0;
}
.navHeaderLogoTextBox{
  display: flexbox;    
  height: 1; 
  font-family: 'gothice', 'Arial Narrow', Arial, sans-serif ; 
  margin-left: 8px;
  
}
.navHeaderLogoText{ 
  display: flex; 
  height: 100%;
  align-items: center;  
  font-family: 'gothice', 'Arial Narrow', Arial, sans-serif ;  
} 

#signInImg{
  height: 36px;
  width: 36px; 
  border-radius: 50%;
  object-fit: cover;
  font-size: 11px;
  text-align: center;
}
.downloadCard{  
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(255, 0, 242); */
}


.downloadCardLogo{  
  display: flex; 
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  /* background-color: rgb(0, 255, 13); */
}

.downloadCardLogo:hover{   
  transition: all 0.2s ease-in-out; 
  background-color: rgb(236, 236, 236);
  /* background-color: rgb(0, 255, 13); */
}


.downloadCardLogoImg{  
  display: flex;
  align-items: center;
  justify-content: center; 
  /* background-color: rgb(34, 85, 114); */
}



.downloadCardText{  
  display:inline-block;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(255, 217, 0); */
}


.downloadCardTextHead{  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-size: 13px;
  font-weight:normal; 
  font-family: sans-serif, Arial, Helvetica ;
  text-align: center;
  color: rgb(40, 0, 73);
  /* background-color: rgb(255, 0, 242); */
}

.downloadCardTextSubHead{  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  font-size: 22px;
  font-weight:normal; 
  font-family: sans-serif, Arial, Helvetica ;
  text-align: center;
  color: rgb(18, 0, 39); 
}
.blurBg{
  backdrop-filter: blur(8px); 
  background-color: rgba(0, 0, 0, 0.65); 
}
.privacyPolicyHeading{
  font-size: 36px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  margin-top: 48px;
  margin-bottom: 24px; 
  margin-left: 18px; 
  margin-right: 18px; 
  color: white;
}

.privacyPolicySubHeading{
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: left;
  margin-top: 18px;
  margin-bottom: 6px;
  margin-left: 18px; 
  margin-right: 18px; 
  color: white;
}

.privacyPolicyText{
  font-size: 16px; 
  font-weight: normal;
  text-align: left; 
  margin-top: 5px;
  margin-left: 18px; 
  margin-right: 18px; 
}

.sidenav {
  height: 100%;  
  width: 200px;  
  position: fixed; 
  z-index: 2;  
  top: 0; 
  left: 0;
  background-image: linear-gradient(to left, #096ca5, #04061f); 
  overflow-x: hidden;  
  padding-top: 50px; 
  transition: all 0.25s ease-in-out; 
}
 
.sidenav a {
  padding: 14px 18px 6px 16px;
  text-decoration: none;
  font-size: 22px;
  color: #d3d3d3;
  display: block; 
}
 
.sidenav a:hover {
  transition: all 0.2s ease-in-out; 
  color: #ffffff;
}
  
.signInCard { 
  width: 350px; 
  box-shadow: 0 15px 25px rgba(180, 180, 180, 0.6); 
  height: 400px; 
  border-radius: 18px; 
  backdrop-filter: blur(4px); 
  background-color: rgba(11, 23, 58, 0.65); 
  padding: 10px; 
  text-align: center; 
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
} 

.signInHeading{
  font-size: 32px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px; 
  color: white;
}

.signInUserID{
  padding-top: 24px;
}
.signInUserPassword{
  padding-top: 24px;
}
.cardInputText{
  color: #ffffff;
}

.signInLogIn{
  padding-top: 24px;
  padding-left: 10%;
  padding-right: 10%;
}

.signInForgot{
  padding-top: 8px;
  display:inline-block;
  align-items: right;
  justify-content: right;
  padding-left: 50%;
  padding-right: 4.5%;
  color: rgb(253, 218, 19);
}

.signInLoader{
  text-align: center; 
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.slideClass{
  height: 100%;  
  width: 100%;   
  margin-top: 30px;
}

.footer{
  position: relative;
  bottom: 0;   
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  backdrop-filter: blur(4px); 
  background-color: #040e27ce; 
	z-index: 0.99;  
}

 
.footer .footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3.5rem;
  padding: 25px;
}

.footer-row .footer-col h4 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
}

.footer-col .links {
  margin-top: 18px;
}

.footer-col .links li {
  list-style: none;
  margin-bottom: 9px;
}

.footer-col .links li a {
  text-decoration: none;
  color: #bfbfbf;
}

.footer-col .links li a:hover {
  color: #fff;
}

.footer-col p {
  margin: 18px 0;
  color: #bfbfbf;
  max-width: 300px;
}

.footer-col form {
  display: flex;
  gap: 5px;
}

.footer-col input {
  height: 40px;
  border-radius: 6px;
  background: none;
  width: 100%;
  outline: none;
  border: 1px solid #7489C6 ;
  caret-color: #fff;
  color: #fff;
  padding-left: 10px;
}

.footer-col input::placeholder {
  color: #ccc;
}

 .footer-col form button {
  background: #fff;
  outline: none;
  border: none;
  padding: 9px 14px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s ease;
}

.footer-col form button:hover {
  background: #cecccc;
}

.footer-col .icons {
  display: flex;
  margin-top: 20px;
  gap: 30px;
  cursor: pointer;
}

.footer-col .icons i {
  color: #afb6c7;
}

.footer-col .icons i:hover  {
  color: #fff;
}


@media screen and (max-width: 768px) {  
  .heading{  
    font-size: 40px;
  }
  .subHeading{  
    font-size: 22px;
  }
  .navHeaderLogo{  
    font-size: 18px;
  }
  .downloadCardTextSubHead{  
    font-size: 16px;
  }
  .privacyPolicyHeading{
    font-size: 27px;
  }
  .privacyPolicySubHeading{
    font-size: 18px;
  }
  .privacyPolicyText{
    font-size: 14px;  
  }
  .signInCard{
    font-size: 18px;
    width: 320px; 
    height: 450px; 
  }
  .signInHeading{
    font-size: 32px;  
  }
  .signInUserID{
    padding-top: 16px;
  }
  .signInUserPassword{
    padding-top: 16px;
  }
  .signInLogIn{
    padding-top: 18px;
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
  .navHeaderSignIn{ 
    margin-right: 50px; 
  }
  #navHeaderLogoStyle{
    height: 32px;
    width: 32px; 
  }
  #signInImg{
    height: 32px;
    width: 32px;  
  }
  .footer {
    position: relative;
    bottom: 0;
    left: 0;
    transform: none;
    width: 100%;
    border-radius: 0;
  }

  .footer .footer-row {
    padding: 18px;
    gap: 1rem;
  }

  .footer-col form {
    display: block;
  }

  .footer-col form :where(input, button) {
    width: 100%;
  }

  .footer-col form button {
    margin: 9px 0 0 0;
  }
}

@font-face {
  font-family: 'gothice';
  src: local('gothice'), url(./font/gothice_.ttf) format('truetype');
}